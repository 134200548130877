import { Col, Space } from 'antd';
import * as React from 'react';

type Placement = 'topRight' | 'topLeft' | 'topCenter' | 'bottomLeft' | 'bottomCenter' | 'bottomRight';

interface CSCProps {
  cancelFn: () => any;
  continueFn: () => any;
  continueText?: string;
  disableContinue?: boolean;
  hideContinue?: boolean;
  saveAndCloseBtn?: boolean;
  placement?: Placement;
}
export const CancelSaveContinue = (props: CSCProps) => {
  return (
    <Col span="auto" className="cancel-save-continue-btn-group">
      <Space>
        <button className="button cancel-button" data-test-id="cancel_btn" onClick={props.cancelFn}>
          Cancel
        </button>
        {!props.hideContinue && (
          <button
            className="next-button"
            data-test-id="continue_btn"
            disabled={props.disableContinue}
            onClick={props.continueFn}
          >
            {props.continueText || 'Continue'}
          </button>
        )}
      </Space>
    </Col>
  );
};
