import * as React from 'react';
import { matchPath } from 'react-router-dom';
import Breadcrumb from '../../components-v2/Breadcrumb';
import Row from '../../components-v2/Row';
import routes, { Routes } from '../../utils/routeConstants';
import { IntegrationContext } from './../../utils/context';

export interface HeaderProps {
  pathname: string;
  isDistrict: boolean;
}

const findCurrentRouteConfig = (pathname: string, integrationContext): Routes => {
  const definedRoutes = routes(integrationContext);
  let currentRoute = definedRoutes[pathname];

  if (!currentRoute) {
    const matchedPath = Object.keys(definedRoutes).filter(
      (path) => matchPath(pathname, { path, exact: true, strict: true }) !== null,
    );
    if (matchedPath.length) {
      currentRoute = definedRoutes[matchedPath[0]];
    }
  }

  return currentRoute;
};

const Header = (props: HeaderProps): React.ReactElement => {
  const integrationContext = React.useContext(IntegrationContext);
  const currentRoute = findCurrentRouteConfig(props.pathname, integrationContext);
  const baseUrl = `${window.location.protocol}//${window.location.host}`;

  return (
    <div className="main-header-sec">
      <Row justify="start">
        <Breadcrumb>
          <Breadcrumb.Item data-cy={'breadcrumb-naviance-home'}>
            <a href={`${baseUrl}${props.isDistrict ? '/district/main.php' : '/main/dashboards/dashboard.php'}`}>
              Naviance Home
            </a>
          </Breadcrumb.Item>
          <Breadcrumb.Item data-cy={'breadcrumb-naviance-home'}>
            <a href={`${baseUrl}${props.isDistrict ? '/district' : ''}/setupmain/index.php`}>Setup</a>
          </Breadcrumb.Item>
          {currentRoute?.breadcrumbs &&
            currentRoute?.breadcrumbs.map((breadcrumbPath, index) => {
              const breadcrumbRoute = routes(integrationContext)[breadcrumbPath];
              return (
                <Breadcrumb.Item key={index}>
                  <a href={`${window.location.origin}/data-import-new${breadcrumbPath}`}>{breadcrumbRoute?.name}</a>
                </Breadcrumb.Item>
              );
            })}
          <Breadcrumb.Item>
            <a href={`${window.location.origin}/data-import-new${props.pathname}`}>{currentRoute?.name} </a>
          </Breadcrumb.Item>
        </Breadcrumb>
      </Row>
    </div>
  );
};

export default Header;
