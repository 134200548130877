import { environment } from '../../environments/environment';

declare let window: Window;

const { DATA_IMPORT_NEW_UI_CONTEXT } = window;
export const IS_LOCAL_ENV = location.hostname === 'data-import-new.local.naviance.com';
export const API_URL = DATA_IMPORT_NEW_UI_CONTEXT?.API_URL;
export enum ApiClientType {
  TEST_SCORE = '/testscore-admin',
  DATA_INGEST_TOOL = '/data-import-new',
  SUCCEED_API = '',
}
export const IS_TEST_IMPORT_TOGGLE_ON = DATA_IMPORT_NEW_UI_CONTEXT?.toggles?.testImportToggleIsOn;
export const STATE_CODE = DATA_IMPORT_NEW_UI_CONTEXT?.props?.state;
export const HIGHSCHOOL_ID = DATA_IMPORT_NEW_UI_CONTEXT?.HIGHSCHOOL_ID;
export const PSError = 'PSError';
export const TOS_ENDPOINT = '/district/tos.php';
export const PRIVACY_ENDPOINT =
  'https://ui-production.papaya.naviance.com/naviance-static-assets/html/policies/privacy.html';
export const TOU_ENDPOINT = 'https://ui-production.papaya.naviance.com/naviance-static-assets/html/policies/tou.html';

export const OFFICIAL_FORMAT = 'Official';
export const CUSTOM_FORMAT = 'Custom';
export const PUBLISHED_STATUS = 'Published';
export const TEST_STATUS = 'Test';
export const ALL_STATES_CODE = 'ALL_CLIENTS';

export const DATE_FORMAT_NEW = [
  'MM/DD/YYYY',
  'MM-DD-YYYY',
  'MMDDYYYY',
  'MM/DD/YY',
  'MMDDYY',
  'MM/YYYY',
  'MM-YYYY',
  'MMYY',
  'DD/MM/YYYY',
  'DD-MM-YYYY',
  'DDMMYYYY',
  'YYYY/MM/DD',
  'YYYY/DD/MM',
  'YYYY-MM-DD',
  'YYYY-DD-MM',
  'YYYYMMDD',
  'YYYYDDMM',
  'YYYY/MM',
  'YYYY-MM',
  'YYMMDD',
  'YYMM',
];

export const DATE_FORMAT_OLD = [
  'MM/DD/YYYY',
  'MMDDYYYY',
  'MM/DD/YY',
  'MMDDYY',
  'MM/YYYY',
  'MM-YYYY',
  'MMYY',
  'YYYYMMDD',
  'YYMMDD',
  'YYYY/MM',
  'YYYY-MM',
  'YYMM',
];

export const uniqueIdentifierGroup = {
  keyGroupA: ['first_name', 'last_name', 'birthdate'],
  keyGroupB: ['student_id', 'last_name'],
  keyGroupC: ['state_student_id', 'last_name'],
};
export const listOfUniqueIdentifiers = ['first_name', 'last_name', 'birthdate', 'state_student_id', 'student_id'];

export const ALL_STATES_MAP = [
  {
    label: 'All Clients',
    value: 'ALL_CLIENTS',
  },
  {
    label: 'Alabama',
    value: 'AL',
  },
  {
    label: 'Alaska',
    value: 'AK',
  },
  {
    label: 'Arizona',
    value: 'AZ',
  },
  {
    label: 'Arkansas',
    value: 'AR',
  },
  {
    label: 'California',
    value: 'CA',
  },
  {
    label: 'Colorado',
    value: 'CO',
  },
  {
    label: 'Connecticut',
    value: 'CT',
  },
  {
    label: 'Delaware',
    value: 'DE',
  },
  {
    label: 'Florida',
    value: 'FL',
  },
  {
    label: 'Georgia',
    value: 'GA',
  },
  {
    label: 'Hawaii',
    value: 'HI',
  },
  {
    label: 'Idaho',
    value: 'ID',
  },
  {
    label: 'Illinois',
    value: 'IL',
  },
  {
    label: 'Indiana',
    value: 'IN',
  },
  {
    label: 'Iowa',
    value: 'IA',
  },
  {
    label: 'Kansas',
    value: 'KS',
  },
  {
    label: 'Kentucky',
    value: 'KY',
  },
  {
    label: 'Louisiana',
    value: 'LA',
  },
  {
    label: 'Maine',
    value: 'ME',
  },
  {
    label: 'Maryland',
    value: 'MD',
  },
  {
    label: 'Massachusetts',
    value: 'MA',
  },
  {
    label: 'Michigan',
    value: 'MI',
  },
  {
    label: 'Minnesota',
    value: 'MN',
  },
  {
    label: 'Mississippi',
    value: 'MS',
  },
  {
    label: 'Missouri',
    value: 'MO',
  },
  {
    label: 'Montana',
    value: 'MT',
  },
  {
    label: 'Nebraska',
    value: 'NE',
  },
  {
    label: 'Nevada',
    value: 'NV',
  },
  {
    label: 'New Hampshire',
    value: 'NH',
  },
  {
    label: 'New Jersey',
    value: 'NJ',
  },
  {
    label: 'New Mexico',
    value: 'NM',
  },
  {
    label: 'New York',
    value: 'NY',
  },
  {
    label: 'North Carolina',
    value: 'NC',
  },
  {
    label: 'North Dakota',
    value: 'ND',
  },
  {
    label: 'Ohio',
    value: 'OH',
  },
  {
    label: 'Oklahoma',
    value: 'OK',
  },
  {
    label: 'Oregon',
    value: 'OR',
  },
  {
    label: 'Pennsylvania',
    value: 'PA',
  },
  {
    label: 'Rhode Island',
    value: 'RI',
  },
  {
    label: 'South Carolina',
    value: 'SC',
  },
  {
    label: 'South Dakota',
    value: 'SD',
  },
  {
    label: 'Tennessee',
    value: 'TN',
  },
  {
    label: 'Texas',
    value: 'TX',
  },
  {
    label: 'Utah',
    value: 'UT',
  },
  {
    label: 'Vermont',
    value: 'VT',
  },
  {
    label: 'Virginia',
    value: 'VA',
  },
  {
    label: 'Washington',
    value: 'WA',
  },
  {
    label: 'West Virginia',
    value: 'WV',
  },
  {
    label: 'Wisconsin',
    value: 'WI',
  },
  {
    label: 'Wyoming',
    value: 'WY',
  },
];

export const groupingFlags = {
  'Student Readiness Indicators': 'release.succeed.sriGrouping',
  'Add state or supplemental test scores for existing students': 'release.succeed.importTestScores',
};

export const FileTypes =
  'application/zip, application/x-zip-compressed, multipart/x-zip, text/plain, text/csv, application/vnd.ms-excel, application/x-csv, text/comma-separated-values, text/x-comma-separated-values, text/tab-separated-values';

export const FileUploadButtonText = {
  NO_FILE_CHOSEN: 'No File Chosen',
  REPLACE_FILE: 'Replace File',
  CHOOSE_FILE: 'Choose File',
};
