import { configureStore, createSlice } from '@reduxjs/toolkit';

export const initialState = {
  groupingId: '',
  groupingName: '',
  dataTypeId: '',
  dataTypeName: '',
  dataTypeFormat: '',
  dateFields: {},
  fileName: '',
  uploadedFileName: '',
  config: '',
  modifiedConfig: '',
  officialFileFormatLink: '',
  uniqueIdentifier: ['first_name', 'last_name', 'birthdate'],
  SelectedUniqueIdentifier: 'First Name, Last Name, and Birthdate',
  uniqueIdentifierKey: 'keyGroupA',
  firstRowContainColumnHeadings: true,
  fileHeaders: [],
  fileDataAllRows: [],
  fileDataFirstThreeRows: [],
  fieldsData: [],
  donotOverwriteNavianceValueIfBlank: false,
  donotOverwriteNavianceValueIfMet: false,
  overwriteData: false,
  selectedColumns: [],
  matchedColumns: {},
  checkEmptyNavianceField: false,
  emptyNavianceFields: [],
};

const dataSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    addGroupingId(state, action) {
      state.groupingId = action.payload;
    },
    addGroupingName(state, action) {
      state.groupingName = action.payload;
    },
    addDataTypeId(state, action) {
      state.dataTypeId = action.payload;
    },
    addDataTypeName(state, action) {
      state.dataTypeName = action.payload;
    },
    addDataTypeFormat(state, action) {
      state.dataTypeFormat = action.payload;
    },
    addDateFields(state, action) {
      state.dateFields = action.payload;
    },
    addFileName(state, action) {
      state.fileName = action.payload;
    },
    addUploadedFileName(state, action) {
      state.uploadedFileName = action.payload;
    },
    addConfig(state, action) {
      state.config = action.payload;
    },
    addModifiedConfig(state, action) {
      state.modifiedConfig = action.payload;
    },
    removeSelectedFileName(state) {
      state.fileName = '';
    },
    setofficialFileFormatLink(state, action) {
      state.officialFileFormatLink = action.payload;
    },
    setUniqueIdentifier(state, action) {
      state.uniqueIdentifier = action.payload;
    },
    setUniqueIdentifierKey(state, action) {
      state.uniqueIdentifierKey = action.payload;
    },
    setFirstRowContainColumnHeadings(state) {
      state.firstRowContainColumnHeadings = !state.firstRowContainColumnHeadings;
    },
    addFileheaders(state, action) {
      state.fileHeaders = action.payload;
    },
    addFileDataAllRows(state, action) {
      state.fileDataAllRows = action.payload;
    },
    addFileDataFirstThreeRows(state, action) {
      state.fileDataFirstThreeRows = action.payload;
    },
    addFieldsData(state, action) {
      state.fieldsData = action.payload;
    },
    setDonotOverwriteNavianceValueIfBlank(state) {
      state.donotOverwriteNavianceValueIfBlank = !state.donotOverwriteNavianceValueIfBlank;
    },
    setDonotOverwriteNavianceValueIfMet(state) {
      state.donotOverwriteNavianceValueIfMet = !state.donotOverwriteNavianceValueIfMet;
    },
    setOverwriteData(state) {
      state.overwriteData = !state.overwriteData;
    },
    setSelectedColumns(state, action) {
      state.selectedColumns = action.payload;
    },
    setMatchedColumns(state, action) {
      state.matchedColumns = action.payload;
    },
    setCheckEmptyNavianceField(state, action) {
      state.checkEmptyNavianceField = action.payload;
    },
    setEmptyNavianceFields(state, action) {
      state.emptyNavianceFields = action.payload;
    },
    resetReduxStates: () => initialState,
  },
});

const store = configureStore({ reducer: dataSlice.reducer });

export const dataActions = dataSlice.actions;
export const actions = dataSlice.actions;
export const reducer = dataSlice.reducer;
export default store;
