import * as React from 'react';
import Loader from '../../../assets/Icon/Loader';
import { LoadingStyled } from './styles';

interface ILoading {
  title?: string;
  className?: string;
}

const Loading = (props: ILoading) => (
  <LoadingStyled {...props}>
    <Loader />
    {props.title}
  </LoadingStyled>
);

export default Loading;
