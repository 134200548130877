import './app.scss';

import React, { ReactElement, useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Shell from './containers/Shell';
import { IntegrationContext } from './utils/context';
import { getFeatureFlags } from './utils/featureFlags';
import ScrollToTop from './utils/scrollToTop';

export const App = (): ReactElement => {
  let isCleverIntegration = false;
  let dataImportPath = '/data-import-new';
  const [isFeatureFlagFetched, setFeatureFlags] = useState(false);

  const init = async () => {
    await getFeatureFlags();
    setFeatureFlags(true);
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <Router basename="/data-import-new">
      <ScrollToTop />
      <IntegrationContext.Provider value={{ isCleverIntegration, dataImportPath, isFeatureFlagFetched }}>
        <Shell />
      </IntegrationContext.Provider>
    </Router>
  );
};

export default App;
