import { notification } from 'antd';
import React from 'react';

export enum NotificationTypes {
  warning = 'warning',
  success = 'success',
  info = 'info',
  error = 'error',
}

export const showNotification = (
  type: string,
  message: string | React.ReactNode,
  description: string | React.ReactNode,
  style = {},
  icon: React.ReactNode = null,
  duration = 4,
): void => {
  notification[type]({
    message,
    description,
    top: 100,
    placement: 'topRight',
    duration,
    style,
    ...(icon ? { icon } : {}),
  });
};
