import { Button, Col, Dropdown, Menu, Row, Table, TablePaginationConfig, Modal } from 'antd';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import AlertErrorIcon from '../../../assets/Icon/AlertErrorIcon';
import AlertYellowIcon from '../../../assets/Icon/AlertYellowIcon';
import CheckmarkCircleIcon from '../../../assets/Icon/CheckmarkCircleIcon';
import MoreVerticalIcon from '../../../assets/Icon/MoreVerticalICon';
import { NotificationTypes, showNotification } from '../../components/Notifications';
import { apiClient } from '../../utils/apiClient';
import MainTemplate from '../Shell/MainTemplate';
import { ApiClientType, HIGHSCHOOL_ID } from '../../utils/constants';
import Title from 'antd/lib/typography/Title';
import Paragraph from 'antd/lib/typography/Paragraph';
import { CheckCircleFilled } from '@ant-design/icons';
import { featureFlags } from '../../utils/featureFlags';

const DataImport: React.FC = () => {
  const history = useHistory();
  const [importRecords, setImportRecords] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [totalJobs, setTotalJobs] = React.useState(0);
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [refreshData, setRefreshData] = React.useState(false);
  const [jobIDToUndoImport, setjobIDToUndoImport] = React.useState({
    jobId: '',
    dataType: '',
    author: '',
  });

  const DataImportTableColumns = [
    {
      title: 'Data Type',
      dataIndex: 'dataType',
      key: 'dataType',
      width: '15%',
      render: (dataType) => <span data-test-id="data_type">{dataType}</span>,
    },
    {
      title: 'Start Time',
      dataIndex: 'start',
      key: 'start',
      width: '14%',
      render: (date) => <span data-test-id="start_date">{new Date(date).toLocaleString()}</span>,
    },
    {
      title: 'End Time',
      dataIndex: 'end',
      key: 'end',
      width: '14%',
      render: (date) => <span data-test-id="end_date">{date ? new Date(date).toLocaleString() : ''}</span>,
    },
    {
      title: 'User/Source',
      key: 'author',
      render: (row) => (
        <span data-test-id="data_type">
          {row.author && row.author != '' ? row.author : row.source != '-' ? row.source : 'Testscore'}
        </span>
      ),
    },
    {
      title: 'Method',
      render: (row) => <span data-test-id="data_type">{row.source === '-' ? 'Manual' : row.source}</span>,
    },
    {
      title: '# of Rows',
      dataIndex: 'totalRecords',
      key: 'totalRecords',
      render: (totalRecords) => <span data-test-id="num_of_rows">{totalRecords === -1 ? '-' : totalRecords}</span>,
    },
    {
      title: 'Status',
      width: '20%',
      render: (row) => (
        <span data-test-id="status" className="status">
          {getIconAndStatus(row)}
        </span>
      ),
    },
    {
      title: 'Alerts',
      width: '10%',
      render: (row) => {
        return (
          <span data-test-id="alerts">
            {row.done ? (
              <Button type="link" onClick={() => history.push(`/jobs/${row.importDataGuid}`)}>
                {row.noOfAlerts === -1 ? '-' : row.noOfAlerts}
              </Button>
            ) : (
              <span style={{ padding: '4px 15px' }}>{row.noOfAlerts === -1 ? '-' : row.noOfAlerts}</span>
            )}
          </span>
        );
      },
    },
    {
      title: 'More',
      render: (row) => (
        <Dropdown overlay={userMenu(row.importDataGuid, row.undoEnabled, row.dataType, row.author)}>
          <a href="#" onClick={(e) => e.preventDefault()} data-test-id={`${row.importDataGuid}-more_btn`}>
            <MoreVerticalIcon />
          </a>
        </Dropdown>
      ),
    },
  ];

  React.useEffect(() => {
    void fetchJobs();
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    };
  }, [refreshData]);

  React.useEffect(() => {
    // get all undoRecords where status is 'STARTED'
    const tempUndoJob = [];
    if (importRecords.length > 0) {
      for (const job of importRecords) {
        if (job?.undo?.status === 'STARTED') {
          tempUndoJob.push(job);
        }
      }
    }
    if (tempUndoJob.length > 0) {
      updateJob(tempUndoJob);
    }
  }, [importRecords]);

  const updateJob = async (undoRecords) => {
    const { data } = await apiClient(
      { url: `/jobs/${undoRecords[0]?.id}/undoGuid/${undoRecords[0]?.undo?.undoGuid}`, method: 'post' },
      ApiClientType.DATA_INGEST_TOOL,
    );

    setTimeout(() => {
      if (data?.jobConfig?.meta?.undoStatus) {
        const tempRecords = [...importRecords];
        for (let i = 0; i < tempRecords.length; i++) {
          if (tempRecords[i].id === data?.jobConfig?.guid) {
            tempRecords[i].undo.status = data?.jobConfig?.meta?.undoStatus;
          }
        }
        setImportRecords(tempRecords);
      }
    }, 5000);
  };

  //undo import button
  const undoImport = (jobId: string, dataType: string, author?: string) => {
    try {
      setIsModalVisible(true);
      setjobIDToUndoImport({ jobId, dataType, author });
    } catch (err) {
      console.error(err.message);
    }
  };

  const userMenu = (guid: string, undoEnabled: boolean, dataType: string, author?: string) => (
    <Menu>
      <Menu.Item key={`${guid}-log`} data-test-id={`${guid}-menu_log}`} onClick={() => history.push(`/jobs/${guid}`)}>
        View log
      </Menu.Item>
      {undoEnabled && featureFlags['feature.dataIngestNew.undoJobImport'] && (
        <Menu.Item
          key={`${guid}-undo`}
          data-test-id={`${guid}-menu_undo}`}
          onClick={() => undoImport(guid, dataType, author)}
        >
          Undo Import
        </Menu.Item>
      )}
    </Menu>
  );

  const getIconAndStatus = (row) => {
    const testPrefix = row.isTestImport && !row.isStartImport ? 'Test ' : '';
    if (row.undo && row.undo.status) {
      switch (row.undo.status) {
        case 'COMPLETED':
          return (
            <Button type="link" onClick={() => history.push(`/jobs/${row.importDataGuid}`)}>
              <CheckmarkCircleIcon /> Import Undo Completed
            </Button>
          );
        case 'FAILED':
          return (
            <Button type="link" onClick={() => history.push(`/jobs/${row.importDataGuid}`)}>
              <AlertErrorIcon /> Undo Import Failed
            </Button>
          );
        default:
          return <>Undo Import in Progress</>;
      }
    }
    switch (row.navianceStatus) {
      case 'VALIDATION_IN_PROGRESS':
        return <>{testPrefix}Import Validation in progress</>;
      case 'SYNC_VALIDATION_IN_PROGRESS':
        return <>{testPrefix}Sync Validation in Progress</>;
      case 'IMPORT_IN_PROGRESS':
        return <>{testPrefix}Import in progress</>;
      case 'SYNC_IN_PROGRESS':
        return <>{testPrefix}Sync in progress</>;
      case 'IMPORT_COMPLETED':
        return (
          <Button type="link" onClick={() => history.push(`/jobs/${row.importDataGuid}`)}>
            <CheckmarkCircleIcon />
            {testPrefix}Import Complete
          </Button>
        );
      case 'IMPORT_COMPLETED_WITH_ALERTS':
        return (
          <Button type="link" onClick={() => history.push(`/jobs/${row.importDataGuid}`)}>
            <AlertYellowIcon />
            {testPrefix}Import Completed with Alerts
          </Button>
        );
      case 'SYNC_COMPLETED_WITH_ALERTS':
        return (
          <Button type="link" onClick={() => history.push(`/jobs/${row.importDataGuid}`)}>
            <AlertYellowIcon />
            {testPrefix}Sync Completed with Alerts
          </Button>
        );
      case 'CRITICAL_ERROR':
        return (
          <Button type="link" onClick={() => history.push(`/jobs/${row.importDataGuid}`)}>
            <AlertErrorIcon /> Critical Error - Import Cancelled
          </Button>
        );
      // TODO: case 'FILE_REMOVED': return <>File Removed via Undo</>;
      case 'TESTING_COMPLETED':
        return (
          <Button type="link" onClick={() => history.push(`/jobs/${row.importDataGuid}`)}>
            <CheckmarkCircleIcon /> Testing Completed
          </Button>
        );
      case 'TESTING_COMPLETED_WITH_ALERTS':
        return (
          <Button type="link" onClick={() => history.push(`/jobs/${row.importDataGuid}`)}>
            <AlertYellowIcon /> Testing Completed with Alerts
          </Button>
        );
      default:
        return <>{row.navianceStatus}</>;
    }
  };

  const pagination = {
    total: totalJobs,
    showSizeChanger: false,
    onChange: async (page) => await fetchJobs(page),
  } as TablePaginationConfig;

  const fetchJobs = async (page = 1) => {
    try {
      setLoading(true);
      const schoolId = HIGHSCHOOL_ID ?? '0';
      const { data } = await apiClient(
        { url: `/jobs?page=${page}&schoolId=${schoolId}`, method: 'get' },
        ApiClientType.DATA_INGEST_TOOL,
      );
      setImportRecords(data?.jobs || []);
      setTotalJobs(data?.meta.totalJobs || 0);
    } catch (error) {
      showNotification(NotificationTypes.error, 'Error Getting Jobs', 'Failure in getting data from server.');
    } finally {
      setLoading(false);
    }
  };

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    history.push('/');
  };

  const NotificationStyle = {
    backgroundColor: '#D9FCF1',
    borderLeft: 'inset',
    borderLeftColor: '#12D097',
  };

  const undoJob = async (jobGuid: string, dataType: string, author: string) => {
    try {
      setLoading(true);
      const schoolId = HIGHSCHOOL_ID ?? '0';
      const undoBody = {
        dataTypeId: dataType,
        author: author,
      };
      const undoData = { ...undoBody, jobGuid, schoolId };
      const { data } = await apiClient(
        { url: `/testscore/undo`, method: 'post', data: undoData },
        ApiClientType.DATA_INGEST_TOOL,
      );
      setLoading(false);
      showNotification(
        NotificationTypes.success,
        <Title level={5}>Undo Import in Progress</Title>,
        <>
          <Paragraph>Large imports may take a few minutes to complete.</Paragraph>
        </>,
        NotificationStyle,
        <CheckCircleFilled style={{ color: '#12D097' }} />,
      );
    } catch (err) {
      console.log(err.message);
      showNotification(NotificationTypes.error, 'Error in undo import', 'Server Error');
      setLoading(false);
    }
  };

  const onUndoImport = async () => {
    setIsModalVisible(false);
    await undoJob(jobIDToUndoImport.jobId, jobIDToUndoImport.dataType, jobIDToUndoImport.author);
    setRefreshData(!refreshData);
  };

  const onCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <MainTemplate
        title="Data Import History"
        titleTestId="data_import_title"
        showDivider={true}
        // subheaderRSContent={configureBtn}
      >
        <Row>
          <Col span={24}>
            <div className="mainDataImportTable testscoreImportTable">
              <Table
                rowKey="id"
                loading={loading}
                columns={DataImportTableColumns}
                dataSource={importRecords}
                pagination={pagination}
              ></Table>
            </div>
          </Col>
        </Row>
      </MainTemplate>
      <Modal
        visible={isModalVisible}
        centered
        onOk={onUndoImport}
        onCancel={onCancel}
        wrapClassName="start_import_modal"
        okText="Undo Import"
      >
        <Title level={5} style={{ padding: '22px 0px 22px 0px' }}>
          Undo Import?
        </Title>
        <p>Undo will reverse the changes made during this import.</p>
        <p>
          Additionally, any changes made in Course Planner and the Course Catalog since this import occured will be
          lost. The course related data that existed in Naviance prior to this import will be repopulated.
        </p>
      </Modal>
    </>
  );
};

export default DataImport;
