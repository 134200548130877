import React from 'react';
import { render as DOMRender } from 'react-dom';
import App from './app/app';
import { Provider } from 'react-redux';
import store from './app/redux/store';

const render = (Component: React.ReactElement) => {
  DOMRender(Component, document.getElementById('data-import-new-root'));
};

document.addEventListener('DOMContentLoaded', () => {
  render(
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>,
  );
});
