import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { StepStatus, WizardStep } from '../../components-v2/Wizard/sideBar';
import { IntegrationContext } from './../../utils/context';

interface TSWizardStep extends WizardStep {
  content: React.FunctionComponent<any>;
}

export interface TSImportStep {
  title: string;
  content: React.FunctionComponent<any>;
}

export interface TSImportProps {
  steps: TSImportStep[];
}

const stepStatus = (currentStep, stepIndex): StepStatus => {
  return (currentStep === stepIndex ? 'process' : 'wait') as StepStatus;
};

const TSImport = (props: TSImportProps): React.ReactElement => {
  const history = useHistory();
  const [currentStepNum, setCurrentStepNum] = React.useState(0);
  const dataImportPath = '/data-import-new';

  const steps = props.steps.map((step, index) => {
    const status = stepStatus(currentStepNum, index);
    return {
      title: step.title,
      status,
      content: step.content,
    };
  });

  const [currentStep, setCurrentStep] = React.useState<TSWizardStep>(steps[currentStepNum] as any);

  const continueFn = () => {
    const oldStep = currentStepNum;
    const newStep = currentStepNum + 1;

    steps[oldStep].status = stepStatus(newStep, oldStep);

    if (newStep < steps.length) {
      steps[newStep].status = stepStatus(newStep, newStep);
      setCurrentStepNum(newStep);
    } else {
      history.push(dataImportPath);
    }
  };
  const previousFn = (goToStep?: number) => {
    const oldStep = currentStepNum;
    const newStep = goToStep || goToStep === 0 ? goToStep : currentStepNum - 1;

    steps[oldStep].status = stepStatus(newStep, oldStep);
    steps[newStep].status = stepStatus(newStep, newStep);

    setCurrentStepNum(newStep);
  };

  const cancelFn = () => {
    previousFn(0);

    history.push(dataImportPath + '/data-import-new');
  };

  React.useEffect(() => {
    setCurrentStep(steps[currentStepNum] as any);
  }, [currentStepNum]);

  return (
    <currentStep.content
      steps={steps}
      currentStep={currentStepNum}
      cancelFn={cancelFn}
      continueFn={() => {
        continueFn();
      }}
      previousFn={previousFn}
      changeStepFn={previousFn}
    />
  );
};

export default TSImport;
