import { Col, Divider, Row, Spin, Tooltip } from 'antd';
import * as React from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import AlertErrorIcon from '../../../assets/Icon/AlertErrorIcon';
import AlertYellowIcon from '../../../assets/Icon/AlertYellowIcon';
import CheckmarkCircleIcon from '../../../assets/Icon/CheckmarkCircleIcon';
import { NotificationTypes, showNotification } from '../../components/Notifications';
import { ApiClientType } from '../../utils/constants';
import { featureFlags } from '../../utils/featureFlags';
import { StatusMessages } from '../../utils/jobStatus';
import MainTemplate from '../Shell/MainTemplate';
import { apiClient } from '../../utils/apiClient';
import LeftAngleIcon from '../../../assets/Icon/LeftAngleIcon';
import { dataActions } from '../../redux/store';
// component specific styling
import './styles.scss';
import { ErrorAlert } from '../../components-v2/ErrorAlert';

const PSDataImportJobSummary: React.FC = () => {
  const history = useHistory();
  const { jobGuid } = useParams<{ jobGuid: string }>();
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(true);
  const [showError, setShowError] = React.useState(false);
  const [grouping, setGrouping] = React.useState({ groupingId: '', groupingName: '' });
  const [summary, setSummary] = React.useState<any>();
  const [downloadLogLink, setDownloadLogLink] = React.useState('');
  const [downloadInputLink, setDownloadInputLink] = React.useState('');
  const [showAlertOnInvalidLogURL, setShowAlertOnInvalidLogURL] = React.useState(false);
  const [showAlertOnInvalidInputURL, setShowAlertOnInvalidInputURL] = React.useState(false);

  const fetchJob = async () => {
    try {
      const { data } = await apiClient(
        { url: `/testscore/jobs/${jobGuid}`, method: 'get' },
        ApiClientType.DATA_INGEST_TOOL,
      );
      setSummary(data);
      setGrouping({ groupingId: data.groupingId, groupingName: data.groupingName });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setShowError(true);
      console.log(error);
      showNotification(NotificationTypes.error, 'Error Getting Job Details', 'Failure in getting data from server.');
    }
  };

  const getFileURL = async (fileType: string) => {
    try {
      const res = await apiClient(
        {
          url: `/testscore/jobs/${jobGuid}/download/${fileType}`,
          method: 'get',
        },
        ApiClientType.DATA_INGEST_TOOL,
      );
      fileType === 'logs' ? setDownloadLogLink(res.data.url) : setDownloadInputLink(res.data.url);
    } catch (error) {
      fileType === 'logs' ? setShowAlertOnInvalidLogURL(true) : setShowAlertOnInvalidInputURL(true);
      console.log(`error while getting ${fileType} url: `, error);
    }
  };

  //start import button
  const startImport = async () => {
    try {
      setLoading(true);
      const userInfo = await apiClient({ url: `/user/info`, method: 'get' }, ApiClientType.SUCCEED_API);
      const data = {
        author: userInfo?.data?.fullName ?? '',
      };
      await apiClient({ url: `/testscore/job/${jobGuid}/start`, method: 'post', data }, ApiClientType.DATA_INGEST_TOOL);
      setLoading(false);
      history.push(`/import-history`);
    } catch (err) {
      console.error(err.message);
      showNotification(NotificationTypes.error, 'Error Starting Job', 'Server Error');
    }
  };

  React.useEffect(() => {
    setLoading(true);
    void fetchJob();
    getFileURL('logs');
    getFileURL('input');
  }, []);

  if (loading) {
    return (
      <MainTemplate title="">
        <Row justify="center">
          <Col span={12}>
            <div className="spinner">
              <Spin size="large" />
            </div>
          </Col>
        </Row>
      </MainTemplate>
    );
  }

  const { groupingId, groupingName } = grouping;

  const errorRowsPerCategory: { [category: string]: number } = Object.keys(
    summary?.validationResults.errors || {},
  ).reduce((p, c) => {
    return { ...p, [c]: summary?.validationResults.errors[c].count };
  }, {});

  const errorColumnsPerCategory: { [category: string]: string[] } = Object.keys(
    summary?.validationResults.errors || {},
  ).reduce((p, c) => {
    return { ...p, [c]: summary?.validationResults.errors[c].columns };
  }, {});
  const errorCount = Object.values(errorRowsPerCategory).reduce((a, b) => a + b, 0);

  const warningRowsPerCategory: { [category: string]: number } = Object.keys(
    summary?.validationResults.warnings || {},
  ).reduce((p, c) => {
    return { ...p, [c]: summary?.validationResults.warnings[c].count };
  }, {});

  const warningColumnsPerCategory: { [category: string]: string[] } = Object.keys(
    summary?.validationResults.warnings || {},
  ).reduce((p, c) => {
    return { ...p, [c]: summary?.validationResults.warnings[c].columns };
  }, {});
  const totalWarningCount = Object.values(warningRowsPerCategory).reduce((a, b) => a + b, 0);

  const totalErrorCount = errorCount + summary?.studentsNotFound || 0;

  const testPrefix = summary?.isTestImport && !summary?.isStartImport ? 'Test ' : '';

  const getStatusIcons = (importStatus) => {
    switch (importStatus) {
      case 'IMPORT_COMPLETED':
        return <CheckmarkCircleIcon />;
      case 'IMPORT_COMPLETED_WITH_ALERTS':
        return <AlertYellowIcon />;
      case 'SYNC_COMPLETED_WITH_ALERTS':
        return <AlertYellowIcon />;
      case 'CRITICAL_ERROR':
        return <AlertErrorIcon />;
      case 'TESTING_COMPLETED':
        return <CheckmarkCircleIcon />;
      case 'TESTING_COMPLETED_WITH_ALERTS':
        return <AlertYellowIcon />;
      default:
        return '';
    }
  };

  const summarySection = (
    <>
      <h1 className="import-summary-details">{testPrefix}Import Summary</h1>
      <Row>
        <Col span={14}>
          <div className="review-import-details">
            <div>
              <strong>File: </strong>
              {showAlertOnInvalidInputURL ? (
                <>
                  <Tooltip placement="top" title={'File not available for download'}>
                    <span>
                      <AlertErrorIcon customStyles={{ width: '30px', verticalAlign: 'top', marginLeft: '5px' }} />
                    </span>
                  </Tooltip>
                  {summary?.fileName}
                </>
              ) : (
                <a href={downloadInputLink} className="export-log-details">
                  {summary?.fileName ? summary?.fileName : ''}
                </a>
              )}
            </div>
            <div>
              <strong>Data Type: </strong>
              {summary?.dataType}
            </div>
            <div>
              <strong>Field Matching: </strong>
              {summary?.matchedFieldsCount} Matched, {summary?.unmatchedFieldsCount} Unmatched
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
  const detailSection = (
    <>
      <Col span={14}>
        <Divider style={{ marginTop: '35px', marginBottom: '35px' }} />
      </Col>
      <h1 className="import-summary-details">{testPrefix}Import Details</h1>
      <Row>
        <Col span={14}>
          <div className="review-import-details">
            <div>
              <strong>Import Status: </strong>
              {getStatusIcons(summary?.navianceStatus)}
              {summary?.isTestImport && !summary?.isStartImport && summary?.navianceStatus != 'CRITICAL_ERROR'
                ? 'Test '
                : ''}
              {StatusMessages[summary?.navianceStatus]}
            </div>
            <div style={{ margin: '5px 5px 5px 5px' }}>
              <ul>
                <li>
                  <strong>{summary?.totalRecords || 0} Total Records </strong>
                </li>
                <li>
                  <strong> {summary?.recordsUpdated || 0} Records Updated </strong>
                </li>
                <li>
                  <strong> {summary?.totalRecordsSkipped || 0} Records Skipped </strong>
                </li>
              </ul>
            </div>
            {totalErrorCount > 0 ? (
              <ul>
                <li>
                  <strong> {totalErrorCount} Errors: </strong>
                </li>
                <ul>
                  {Object.keys(errorColumnsPerCategory || {}).map((category) => (
                    <li>
                      <strong>
                        {errorRowsPerCategory[category]} {category}:
                      </strong>{' '}
                      {errorColumnsPerCategory[category].join(', ')}
                    </li>
                  ))}
                  {summary?.mergeValidationResults?.invalidData?.count > 0 ? (
                    <li>
                      <strong>{summary?.mergeValidationResults.invalidData.count} invalid Data: </strong>
                      {summary?.mergeValidationResults.invalidData.columns.join(', ')}
                    </li>
                  ) : (
                    ''
                  )}
                  {summary?.mergeValidationResults?.invalidRange?.count > 0 ? (
                    <li>
                      <strong>{summary?.mergeValidationResults.invalidRange.count} invalid Range: </strong>
                      {summary?.mergeValidationResults.invalidRange.columns.join(', ')}
                    </li>
                  ) : (
                    ''
                  )}
                  {summary?.mergeValidationResults?.missingColumn?.count > 0 ? (
                    <li>
                      <strong>{summary?.mergeValidationResults.missingColumn.count} missing Columns: </strong>
                      {summary?.mergeValidationResults.missingColumn.columns.join(', ')}
                    </li>
                  ) : (
                    ''
                  )}
                  {summary?.mergeValidationResults?.maxCharacterLimit?.count > 0 ? (
                    <li>
                      <strong>
                        {summary?.mergeValidationResults.maxCharacterLimit.count} Max Character Limit Error:{' '}
                      </strong>
                      {summary?.mergeValidationResults.maxCharacterLimit.columns.join(', ')}
                    </li>
                  ) : (
                    ''
                  )}
                  {summary?.mergeValidationResults?.studentNotFound > 0 ? (
                    <li>
                      <strong>{summary?.mergeValidationResults.studentNotFound} Student Records Not Found </strong>
                    </li>
                  ) : (
                    ''
                  )}
                  {summary?.mergeValidationResults?.multipleStudentsForUid > 0 ? (
                    <li>
                      <strong>
                        {summary?.mergeValidationResults.multipleStudentsForUid} Duplicate Student Records
                      </strong>
                    </li>
                  ) : (
                    ''
                  )}
                  {summary?.mergeValidationResults?.recordNotOverwritten > 0 ? (
                    <li>
                      <strong>
                        {summary?.mergeValidationResults.recordNotOverwritten} Student Records not overwritten:
                      </strong>{' '}
                      testscore data already exists
                    </li>
                  ) : (
                    ''
                  )}
                </ul>
              </ul>
            ) : (
              ''
            )}
            {totalWarningCount > 0 ? (
              <ul>
                <li>
                  <strong> {totalWarningCount} Warnings: </strong>
                </li>
                <ul>
                  {Object.keys(warningColumnsPerCategory || {}).map((category) => (
                    <li>
                      <strong>
                        {warningRowsPerCategory[category]} {category}:
                      </strong>{' '}
                      {warningColumnsPerCategory[category].join(', ')}
                    </li>
                  ))}
                </ul>
              </ul>
            ) : (
              ''
            )}
            {showAlertOnInvalidLogURL ? (
              <ErrorAlert description="Unable to generate log for this import. Please contact the customer support team if the problem persists." />
            ) : (
              <a href={downloadLogLink} className="export-log-details">
                Export Log for additional details
              </a>
            )}
          </div>
        </Col>
        <Col span={14}>
          <Divider style={{ marginTop: '35px', marginBottom: '35px' }} />
        </Col>
      </Row>
    </>
  );

  return (
    <MainTemplate
      title="View Log"
      titleTestId="data_job_summary_title"
      classes="mainLogsSection mainConfigSection import-log-summary"
    >
      <>
        <Row justify="space-between" align="middle">
          <Col span={4}>
            <div style={{ float: 'left', width: '30px' }}>
              <LeftAngleIcon />
            </div>
            <div>
              <a className="back-button-summary" onClick={() => history.goBack()}>
                Back
              </a>
            </div>
          </Col>
          <Col span={10}>
            <div>
              {summary?.canContinueImport &&
                !summary?.isStartImport &&
                featureFlags['feature.dataIngestNew.viewStartImportFromLogSummary'] && (
                  <div>
                    <button
                      style={{ marginLeft: '10px', float: 'right' }}
                      className="button"
                      onClick={() => startImport()}
                    >
                      Start Import
                    </button>
                  </div>
                )}
              {summary && grouping && (
                <Link
                  style={{ marginLeft: '10px', float: 'right' }}
                  to={{ pathname: `/test-score-import`, state: { groupingId, groupingName } }}
                  className="button"
                  onClick={() => {
                    dispatch(dataActions.addGroupingId(groupingId));
                    dispatch(dataActions.addGroupingName(groupingName));
                  }}
                >
                  Reupload
                </Link>
              )}
            </div>
          </Col>
        </Row>
        {showError ? (
          <ErrorAlert />
        ) : (
          <>
            {featureFlags['feature.dataIngestNew.viewImportDetails'] && summarySection}
            {featureFlags['feature.dataIngestNew.viewImportDetails'] && detailSection}
          </>
        )}
      </>
    </MainTemplate>
  );
};

export default PSDataImportJobSummary;
