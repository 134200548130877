import { Alert } from 'antd';
import Title from 'antd/lib/typography/Title';
import AlertErrorIcon from '../../../assets/Icon/AlertErrorIcon';
import { styles } from '../../../../../data-testscore-ui/src/assets/Icon/CustomIcon';
import * as React from 'react';

interface ErrorAlertProps {
  title?: string;
  description?: string;
}

const defaultProps: ErrorAlertProps = {
  title: 'Error!',
  description:
    'There was an unexpected system error while processing this import. Please contact the customer support team if the problem persist',
};

export const ErrorAlert = (props: ErrorAlertProps): React.ReactElement => {
  const { title, description } = { ...defaultProps, ...props };
  return (
    <Alert
      message={<Title level={5}>{title}</Title>}
      description={description}
      type="error"
      icon={<AlertErrorIcon customStyles={{ ...styles, width: '40px' }} />}
      showIcon
    />
  );
};
