import React, { Fragment, useState, useEffect, useContext } from 'react';
import { useLocation, withRouter } from 'react-router-dom';
import Layout from '../../components-v2/Layout';
import { Header as AntdHeader } from 'antd/lib/layout/layout';
import Routes from '../../routes';
import Header from './Header';
import Footer from './Footer';
import routes from '../../utils/routeConstants';
import { HIGHSCHOOL_ID } from '../../utils/constants';
import { IntegrationContext } from './../../utils/context';

//This component will contain breadcrumbs and headers

const Main = (props): React.ReactElement => {
  const { Content } = Layout;
  const location = useLocation();
  const integrationContext = useContext(IntegrationContext);
  const currentRoute = routes(integrationContext)[location.pathname];
  const showWizard = location.pathname.startsWith('/test-score-import');
  const [isDistrict, setIsDistrict] = useState<any>();

  useEffect(() => {
    setIsDistrict(!HIGHSCHOOL_ID);
  }, []);

  return (
    <Layout>
      {showWizard ? (
        <>
          <AntdHeader className="wizard-header">
            <h2>Data Import</h2>
            <h4>{props.location.state.groupingName}</h4>
          </AntdHeader>
          <currentRoute.page {...(currentRoute.props || {})} />
        </>
      ) : (
        <Content className="main-content">
          <Fragment>
            <Header pathname={location.pathname} isDistrict={isDistrict}></Header>
            <Routes /> <Footer></Footer>
          </Fragment>
        </Content>
      )}
    </Layout>
  );
};
export default withRouter(Main);
