const css = `.titleCol {
  padding-left: 0px !important;
}

.titleField {
  font-family: 'Open Sans';
  font-weight: 400 !important;
  font-size: 28px !important;
  line-height: 38.13px;
  color: #000000 !important;
  padding-left: 0px !important;
}

.test-score-heading {
  font-family: 'Open Sans';
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 0;
}

.data-import-section {
  margin-bottom: 20px;
}
.data-import-key {
  font-family: 'Open Sans';
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 1px;
}

.data-import-link {
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #3b7bc6;

  margin-bottom: 7px;
}

.data-import-item {
  padding-top: 5px;
}

.templates-new-tab-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 35px;
  align-items: center;
  color: #666666;
}

.data-import-template-link {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #666666;
}

.btn-view-history {
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #3b7bc6;
  margin-bottom: 7px;
  padding-left: 0px;
}
`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
