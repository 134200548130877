import React, { useEffect, useState } from 'react';
import { Badge, Col, Row, Table, Button, Modal } from 'antd';
import Wizard, { WizardProps } from '../../components-v2/Wizard';
import { NotificationTypes, showNotification } from '../../components/Notifications';
import { dataActions } from '../../redux/store';
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux';
import { Select } from 'antd';
import { CUSTOM_FORMAT, OFFICIAL_FORMAT, uniqueIdentifierGroup, listOfUniqueIdentifiers } from '../../utils/constants';

const { Option } = Select;
export interface FieldProp {
  id: string;
  name: string;
  groupingId: string;
  dataTypeId: string;
  inputType: string;
  required: boolean;
  columnInFile: string;
  description?: string;
  dateFormat?: string;
  validationType?: string;
  maxCharacter?: number;
  // eslint-disable-next-line @typescript-eslint/ban-types
  acceptableValues?: object;
  range?: {
    min: number;
    max: number;
  };
  CT?: 'string';
  CB?: 'string';
  MD?: 'string';
  MB?: 'string';
}

interface TestScoreMatchFieldsProps extends WizardProps {
  allFieldsRequired: boolean;
  defaultMapping: Record<string, string>;
  header?: React.ReactElement;
  specificMapping?: Record<string, string>;
}

const TSMatchFields = (props: TestScoreMatchFieldsProps): React.ReactElement => {
  const fields = useSelector((state: RootStateOrAny) => state.fieldsData);
  const fileHeaders = useSelector((state: RootStateOrAny) => state.fileHeaders);
  const fileDataFirstThreeRows = useSelector((state: RootStateOrAny) => state.fileDataFirstThreeRows);
  const dataTypeFormat = useSelector((state: RootStateOrAny) => state.dataTypeFormat);
  const storedSelectedColumns = useSelector((state: RootStateOrAny) => state.selectedColumns);
  const uniqueIdentifierKey = useSelector((state: RootStateOrAny) => state.uniqueIdentifierKey);
  const firstRowContainColumnHeadings = useSelector((state: RootStateOrAny) => state.firstRowContainColumnHeadings);
  const [selectedColumns, setSelectedColumns] = useState(Array.from(Array(fields.length).keys()));
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisibleReq, setIsModalVisibleReq] = useState(false);
  const config = useSelector((state: RootStateOrAny) => state.config);
  const updatedFields = JSON.parse(JSON.stringify(fields));
  const dispatch = useDispatch();
  let error_exist = false;

  useEffect(() => {
    if (dataTypeFormat === OFFICIAL_FORMAT) {
      const officialSelectedColumns = [];
      updatedFields.map((item, i) => {
        officialSelectedColumns[i] = lettersToNumber(item.columnInFile) - 1;
      });
      const columns = setNullColumns(officialSelectedColumns);
      setSelectedColumns(columns);
    } else {
      const columns = setNullColumns(selectedColumns);
      setSelectedColumns(columns);
    }
    storedSelectedColumns.length > 0 && setSelectedColumns(storedSelectedColumns);
  }, []);

  const setNullColumns = (selectedColumns): any[] => {
    const columns = [...selectedColumns];
    updatedFields.map((_, i: number) => {
      const newColumnIndex = selectedColumns[i];
      const newSelect = fileHeaders?.[newColumnIndex]?.name ? fileHeaders[newColumnIndex].name : '';
      const selectedValue = newColumnIndex >= 999 ? 'Do not import' : newSelect;
      if (selectedValue == '') {
        columns[i] = 998;
      }
    });
    dispatch(dataActions.addFieldsData(updatedFields));
    return columns;
  };

  const lettersToNumber = (letters) => {
    for (var p = 0, n = 0; p < letters.length; p++) {
      n = letters[p].charCodeAt() - 64 + n * 26;
    }
    return n;
  };

  const save = async (saveAndContinue?: boolean) => {
    if (error_exist) {
      setIsModalVisibleReq(true);
      return;
    }
    if (duplicates.length > 0) {
      setIsModalVisible(true);
      return;
    }
    try {
      if (props.allFieldsRequired) {
        showNotification(NotificationTypes.error, 'Please map all required fields before Saving', '');
        return;
      }
      if (saveAndContinue) {
        dispatch(dataActions.setSelectedColumns(selectedColumns));
        let matchedColumns = {};
        let indexMapping = {};
        updatedFields.map((item, index) => {
          matchedColumns[item.id] = selectedColumns[index];
          if (selectedColumns[index] < 998) {
            indexMapping[selectedColumns[index] + 1] = item.id;
          }
        });
        dispatch(dataActions.setMatchedColumns(matchedColumns));
        await setNewMappings(fieldMap, indexMapping);
        props.continueFn();
      }
    } catch (err) {
      console.error(err.message);
      showNotification(NotificationTypes.error, 'Error Saving Mapping', 'Server Error');
    }
  };

  const cancel = (cancelAndBacktrack?: boolean) => {
    if (cancelAndBacktrack) {
      props.previousFn();
    } else {
      dispatch(dataActions.resetReduxStates());
      props.cancelFn();
    }
  };
  const changeStep = (newStep: number) => {
    props.changeStepFn(newStep);
  };

  //Close Modal handler
  const handleCancelDuplicateRows = () => {
    setIsModalVisible(false);
  };

  const handleCancelRequiredFields = () => {
    setIsModalVisibleReq(false);
  };

  const fileData = {
    fileHeaders,
    fileDataFirstThreeRows,
  };

  const updateNavMaps = (translateConfig: any, validationConfig: any, fieldMap: any, indexMapping: any) => {
    const fieldsNameToIDMappingObj = {};
    for (let obj of fields) {
      if (obj.name && obj.id && !fieldsNameToIDMappingObj[obj.name]) {
        fieldsNameToIDMappingObj[obj.name] = obj.id;
      }
    }

    const getValidationConfig = JSON.parse(JSON.stringify(validationConfig));
    // const mappings = { ...translateConfig?.headerMappings }; // un-used variable

    const modifiedTranslateConfig = {
      ...translateConfig,
      headerMappings: {},
      valueMappings: {},
      headerOrder: [],
      indexMappings: {},
    };

    const modifiedValidationConfig = {
      ...getValidationConfig,
      columns: {},
    };

    // 'i' is the NAME fetched for the fields - fieldsNameToIDMappingObj contains the ID for them
    for (const i in fieldMap) {
      const { headerMappings, headerOrder, valueMappings } = modifiedTranslateConfig;
      if (fieldMap[i] != '' && fieldMap[i] != 'Do not import') {
        headerMappings[fieldMap[i]] = fieldsNameToIDMappingObj[i];
        if (translateConfig.valueMappings[fieldsNameToIDMappingObj[i]]) {
          valueMappings[fieldsNameToIDMappingObj[i]] = translateConfig.valueMappings[fieldsNameToIDMappingObj[i]];
        }
        for (const field of fields) {
          if (field.name === i) {
            modifiedValidationConfig.columns[fieldsNameToIDMappingObj[i]] =
              validationConfig.columns[fieldsNameToIDMappingObj[i]];
            headerOrder.push(field.id);
          }
        }
      }
    }
    if (!firstRowContainColumnHeadings) {
      modifiedTranslateConfig.indexMappings = JSON.parse(JSON.stringify(indexMapping));
      modifiedTranslateConfig.headerlessFile = true;
      modifiedTranslateConfig.headerOrder = Object.values(modifiedTranslateConfig.indexMappings);
      delete modifiedTranslateConfig.headerMappings;
    }
    return { translateConfig: modifiedTranslateConfig, validateConfig: modifiedValidationConfig };
  };
  const setNewMappings = (fieldMap, indexMapping) => {
    let { translateConfig, validateConfig } = config;
    let updatedConfig = { translateConfig, validateConfig };
    if (translateConfig && validateConfig) {
      updatedConfig = updateNavMaps(translateConfig, validateConfig, fieldMap, indexMapping);
    }

    dispatch(dataActions.addModifiedConfig(updatedConfig));
  };

  const changeOfDropdown = (value: string, e) => {
    const columns = [...selectedColumns];
    if (value != 'DNI') {
      columns[e.datatype.row] = parseInt(value);
      setSelectedColumns(columns);
      dispatch(dataActions.setSelectedColumns(columns));
      return;
    }
    columns[e.datatype.row] = 999;
    setSelectedColumns(columns);
    dispatch(dataActions.setSelectedColumns(columns));
  };

  const fieldMap = {};
  const getTableData = (selectedColumns): any[] => {
    return updatedFields.map((item, i) => {
      const { name, required } = item;

      const { fileHeaders, fileDataFirstThreeRows } = fileData;

      const newColumnIndex = selectedColumns[i];

      const newSelect = fileHeaders?.[newColumnIndex]?.name ? fileHeaders[newColumnIndex].name : '';
      let selectedValue = '';
      if (newColumnIndex >= 999) {
        selectedValue = 'Do not import';
        fieldMap[name] = selectedValue;
      } else {
        selectedValue = newSelect;
        fieldMap[name] = selectedValue.replace(/_[^_]+$/, '');
      }

      if (fileHeaders && fileDataFirstThreeRows) {
        return {
          columnIndex: newColumnIndex && newColumnIndex,
          fieldIndex: i,
          columnSelected: selectedColumns,
          required,
          name,
          selectedHeader: selectedValue,
          selectedRowOne: fileDataFirstThreeRows[0]
            ? fileDataFirstThreeRows?.[0][fileHeaders[newColumnIndex]?.name]
            : '',
          selectedRowTwo: fileDataFirstThreeRows[1]
            ? fileDataFirstThreeRows?.[1][fileHeaders[newColumnIndex]?.name]
            : '',
          selectedRowThree: fileDataFirstThreeRows[2]
            ? fileDataFirstThreeRows?.[2][fileHeaders[newColumnIndex]?.name]
            : '',
          dropDownFileHeader: fileHeaders,
          fileDataFirstThreeRows,
        };
      } else {
        return {
          columnIndex: newColumnIndex && newColumnIndex,
          required,
          name,
          selectedHeader: '',
          selectedRowOne: '',
          selectedRowTwo: '',
          selectedRowThree: '',
          dropDownFileHeader: [],
        };
      }
    });
  };

  let duplicateArray = [...selectedColumns];
  duplicateArray = duplicateArray.filter(function (item) {
    return item !== 999 && item !== 998;
  });

  const findDuplicates = (arry) => arry.filter((item, index) => arry.indexOf(item) !== index);
  const duplicates = selectedColumns.length > 0 && findDuplicates(duplicateArray);

  const DataImportTableColumnsOfficialFormat = [
    {
      title: 'Naviance Field',
      width: '30%',
      render: (nav) => (
        <span title={nav.name}>
          {nav.name}
          {nav.required ? (
            <Badge
              count={'required'}
              style={{
                backgroundColor: '#ffffff',
                color: '#939d9d',
                border: '1px solid #939d9d',
                borderRadius: '4px',
                marginLeft: '10px',
              }}
            />
          ) : (
            ''
          )}
        </span>
      ),
    },
    {
      title: 'First row in file',
      width: '40%',
      render: ({ selectedHeader }) => <span>{selectedHeader.replace(/_[^_]+$/, '')}</span>,
    },
    {
      title: 'Preview of data in file',
      width: '30%',
      render: ({ selectedRowOne, selectedRowTwo, selectedRowThree }) => {
        return (
          <>
            <p style={{ margin: '0 0 0 0', paddingTop: '5px' }}>{selectedRowOne}</p>
            <p style={{ margin: '0 0 0 0', paddingTop: '5px' }}> {selectedRowTwo}</p>
            <p style={{ margin: '0 0 0 0', paddingTop: '5px', paddingBottom: '5px' }}>{selectedRowThree}</p>
          </>
        );
      },
    },
  ];

  const DataImportTableColumnsCustomFormat = [
    {
      title: 'Naviance Field',
      width: '25%',
      render: (nav) => (
        <span title={nav.name}>
          {nav.name}
          {nav.required ? (
            <Badge
              count={'required'}
              style={{
                backgroundColor: '#ffffff',
                color: '#939d9d',
                border: '1px solid #939d9d',
                borderRadius: '4px',
                marginLeft: '10px',
              }}
            />
          ) : (
            ''
          )}
        </span>
      ),
    },
    {
      title: 'First row in file',
      width: '45%',
      render: ({ dropDownFileHeader, fileDataFirstThreeRows, columnIndex, fieldIndex, required, name }) => {
        const newSelect = dropDownFileHeader?.[columnIndex]?.name ? dropDownFileHeader[columnIndex].name : '';
        let selectedValue = columnIndex >= 999 ? 'Do not import' : newSelect;
        let error_message = '';

        if (required && (!selectedValue || selectedValue.replace(/_[^_]+$/, '')?.length < 1)) {
          error_exist = true;
          error_message = 'This is a required field and it cannot be empty';
        }
        return (
          <>
            <Select
              className="select select-text first-row-in-file"
              style={{ width: 350 }}
              onChange={changeOfDropdown}
              defaultValue={selectedValue.replace(/_[^_]+$/, '')}
            >
              {!required && (
                <Option className="dropdown-first-row-in-file" key="DNI" value="DNI" datatype={{ row: fieldIndex }}>
                  <span className="dropdown-first-row">Do not import</span>
                </Option>
              )}
              {dropDownFileHeader.map((item, index) => {
                const selectedRowOne = fileDataFirstThreeRows[0]
                  ? fileDataFirstThreeRows?.[0][fileHeaders[index]?.name]
                  : '';
                const selectedRowTwo = fileDataFirstThreeRows[1]
                  ? fileDataFirstThreeRows?.[1][fileHeaders[index]?.name]
                  : '';
                const selectedRowThree = fileDataFirstThreeRows[2]
                  ? fileDataFirstThreeRows?.[2][fileHeaders[index]?.name]
                  : '';

                return (
                  <Option
                    className="dropdown-first-row-in-file"
                    key={index + fieldIndex + item.name}
                    value={index}
                    datatype={{ item: item, row: fieldIndex }}
                  >
                    <span className="dropdown-first-row">{item.name.replace(/_[^_]+$/, '')}</span>
                    <p className="dropdown-row-values">
                      {selectedRowOne ? selectedRowOne : ''}
                      {selectedRowTwo ? ',' + selectedRowTwo : ''}
                      {selectedRowThree ? ',' + selectedRowThree : ''}
                    </p>
                  </Option>
                );
              })}
            </Select>
            <p style={{ color: 'red' }}>{error_message}</p>
          </>
        );
      },
    },

    {
      title: 'Preview of data in file',
      width: '25%',
      render: ({ selectedRowOne, selectedRowTwo, selectedRowThree }) => {
        return (
          <>
            <p style={{ margin: '0 0 0 0', paddingTop: '5px' }}>{selectedRowOne}</p>
            <p style={{ margin: '0 0 0 0', paddingTop: '5px' }}> {selectedRowTwo}</p>
            <p style={{ margin: '0 0 0 0', paddingTop: '5px', paddingBottom: '5px' }}>{selectedRowThree}</p>
          </>
        );
      },
    },
  ];

  const mainContent = () => {
    const dataSourceContent = getTableData(selectedColumns);
    return (
      <>
        <div className="subSections">
          <div className="subSection">
            {props.header ?? <></>}
            <Row justify="space-between" align="bottom" style={{ margin: '8px 0px' }}>
              <Col>
                {dataTypeFormat === 'Official' ? (
                  <span className="subsection-match-fields">
                    Your data has been auto-matched to the corresponding Naviance fields based on the official format of
                    your file.
                  </span>
                ) : (
                  <span className="subsection-match-fields">
                    Your data has been auto-matched to the corresponding Naviance fields and you can edit the matches as
                    needed.
                  </span>
                )}
              </Col>
            </Row>
          </div>
          <div>
            <MatchingTable
              selectedColumns
              rowKey={(obj) => obj.columnIndex + obj.name}
              dataTypeFormat
              DataImportTableColumnsCustomFormat
              DataImportTableColumnsOfficialFormat
              columns={
                dataTypeFormat === CUSTOM_FORMAT
                  ? DataImportTableColumnsCustomFormat
                  : DataImportTableColumnsOfficialFormat
              }
              dataSource={dataSourceContent}
              pagination={false}
            />

            <Modal
              title="Alert"
              visible={isModalVisible}
              onCancel={handleCancelDuplicateRows}
              centered
              footer={[
                <Button key="cancel" type="primary" onClick={handleCancelDuplicateRows}>
                  OK
                </Button>,
              ]}
            >
              <p className="modal-text">
                Duplicate dropdown rows selected :
                {fileHeaders && fileHeaders[duplicates[0]]?.name.replace(/_[^_]+$/, '')}, Please select unique dropdown
                rows
              </p>
            </Modal>

            <Modal
              title="Alert"
              visible={isModalVisibleReq}
              onCancel={handleCancelRequiredFields}
              centered
              footer={[
                <Button key="cancel" type="primary" onClick={handleCancelRequiredFields}>
                  OK
                </Button>,
              ]}
            >
              <p className="modal-text">All required fields are mandatory.</p>
            </Modal>
          </div>
        </div>
      </>
    );
  };

  return (
    <Wizard
      steps={props.steps}
      currentStep={props.currentStep}
      continueFn={() => save(true)}
      previousFn={() => cancel(true)}
      cancelFn={() => cancel()}
      changeStepFn={changeStep}
      hideHeaderButtons={true}
    >
      {mainContent()}
    </Wizard>
  );
};

export default TSMatchFields;

const MatchingTable = (props) => {
  const {
    selectedColumns,
    dataSource,
    DataImportTableColumnsCustomFormat,
    DataImportTableColumnsOfficialFormat,
    dataTypeFormat,
  } = props;
  useEffect(() => {}, [selectedColumns, dataSource]);

  return (
    <>
      <Table {...props} />
    </>
  );
};
