import { Steps } from 'antd';
import CircleIcon from '../../../assets/Icon/CircleIcon';
import * as React from 'react';

export type StepStatus = 'wait' | 'process' | 'finish' | 'error';

export interface WizardStep {
  title: string;
  status: StepStatus;
}

export interface WizardSideBarProps {
  steps: WizardStep[];
  onChange: (newStep: number) => void;
}

const WizardSideBar = (props: WizardSideBarProps): React.ReactElement => {
  const { Step } = Steps;

  return (
    <Steps
      className="wizard-steps"
      direction="vertical"
      progressDot={() => <CircleIcon />}
      onChange={props.onChange}
      current={null}
    >
      {props.steps.map((step, indx) => {
        return (
          <Step
            title={step.title}
            disabled
            status={step.status}
            key={indx}
            className={`wizard-step-${step.status}`}
          ></Step>
        );
      })}
    </Steps>
  );
};

export default WizardSideBar;
