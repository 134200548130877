import React from 'react';
import { useHistory } from 'react-router-dom';
import { Divider, Col, Button, Modal, Alert } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
import Title from 'antd/lib/typography/Title';
import Paragraph from 'antd/lib/typography/Paragraph';

import Checkbox from '../../components-v2/Checkbox';
import Wizard, { WizardProps } from '../../components-v2/Wizard';
import { NotificationTypes, showNotification } from '../../components/Notifications';
import { apiClient } from '../../utils/apiClient';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { dataActions } from '../../redux/store';
import { ApiClientType, uniqueIdentifierGroup, HIGHSCHOOL_ID } from '../../utils/constants';
import { sendSlackNotifications } from '../../utils/notifications';
import { featureFlags } from '../../utils/featureFlags';
import { FeatureFlag } from '../../enums/FeatureFlag';
import AlertErrorIcon from '../../../assets/Icon/AlertErrorIcon';
import { styles } from '../../../../../data-testscore-ui/src/assets/Icon/CustomIcon';

export const NotificationStyle = {
  backgroundColor: '#D9FCF1',
  borderLeft: 'inset',
  borderLeftColor: '#12D097',
};

export interface TestScoreStartImportProps extends WizardProps {
  allFieldsRequired: boolean;
  defaultMapping: Record<string, string>;
  translateConfigEndPoint: string;
  header?: React.ReactElement;
  specificMapping?: Record<string, string>;
}

const TSStartImport = (props: TestScoreStartImportProps): React.ReactElement => {
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const headers = useSelector((state: RootStateOrAny) => state.fieldsData);
  const fileName = useSelector((state: RootStateOrAny) => state.fileName);
  const dataTypeName = useSelector((state: RootStateOrAny) => state.dataTypeName);
  const dataTypeId = useSelector((state: RootStateOrAny) => state.dataTypeId);
  const groupingId = useSelector((state: RootStateOrAny) => state.groupingId);
  const groupingName = useSelector((state: RootStateOrAny) => state.groupingName);
  const dataTypeFormat = useSelector((state: RootStateOrAny) => state.dataTypeFormat);
  const uploadedFileName = useSelector((state: RootStateOrAny) => state.uploadedFileName);
  const matchedColumns = useSelector((state: RootStateOrAny) => state.matchedColumns);
  const [showConfirmationModal, setShowConfirmationModal] = React.useState(false);
  const [showErrorAlert, setShowErrorAlert] = React.useState(false);
  const donotOverwriteNavianceValueIfBlank = useSelector(
    (state: RootStateOrAny) => state.donotOverwriteNavianceValueIfBlank,
  );
  const overwriteData = useSelector((state: RootStateOrAny) => state.overwriteData);
  const donotOverwriteNavianceValueIfMet = useSelector(
    (state: RootStateOrAny) => state.donotOverwriteNavianceValueIfMet,
  );
  const dateFields = useSelector((state: RootStateOrAny) => state.dateFields);
  const uniqueIdentifierKey = useSelector((state: RootStateOrAny) => state.uniqueIdentifierKey);

  const dispatch = useDispatch();
  let count = 0;
  let unmatchedFieldsCount = 0;
  for (const key in matchedColumns) {
    ++count;
    if (matchedColumns[key] >= 998) unmatchedFieldsCount++;
  }
  const matchedFieldsCount = count - unmatchedFieldsCount;

  const startImport = async (isTestImport = false) => {
    let data = {
      isTestImport,
      dataTypeName,
      dataTypeId,
      uploadedFileName,
      donotOverwriteNavianceValueIfBlank,
      overwriteData,
      donotOverwriteNavianceValueIfMet,
      uniqueIdentifiers: uniqueIdentifierGroup[uniqueIdentifierKey].join(','),
      dateFields,
      fileName,
      matchedFieldsCount,
      unmatchedFieldsCount,
      groupingId,
      groupingName,
    };

    try {
      setLoading(true);
      const uniqueFieldDetails = headers.find((obj) => obj.isUnique);
      const uniqueField = uniqueFieldDetails ? uniqueFieldDetails.id : '';
      const userInfo = await apiClient({ url: `/user/info`, method: 'get' }, ApiClientType.SUCCEED_API);
      data = {
        ...data,
        ...{
          author: userInfo?.data?.fullName ?? '',
          schoolId: HIGHSCHOOL_ID ?? '',
          districtId: userInfo?.data?.districtId ?? '',
          uniqueField,
        },
      };

      await apiClient({ url: '/testscore/start', method: 'post', data }, ApiClientType.DATA_INGEST_TOOL);
      setLoading(false);

      if (!isTestImport) {
        setShowConfirmationModal(true);
      } else {
        showNotification(
          NotificationTypes.success,
          <Title level={5}>Test Import in Progress</Title>,
          <>
            <Paragraph>Large imports may take a few minutes to complete.</Paragraph>
            <Paragraph type="secondary">{new Date().toLocaleString()}</Paragraph>
          </>,
          NotificationStyle,
          <CheckCircleFilled style={{ color: '#12D097' }} />,
        );
        onOk();
      }
    } catch (err) {
      console.error(err.message);
      if (featureFlags[FeatureFlag.SLACK_NOTIFICATION]) {
        sendSlackNotifications({ message: err.message, data });
      }
      setLoading(false);
      setShowErrorAlert(true);
    }
  };

  const save = async (saveAndContinue?: boolean) => {
    try {
      if (props.allFieldsRequired) {
        showNotification(NotificationTypes.error, 'Please map all required fields before Saving', '');
        return;
      }
      if (saveAndContinue) {
        props.continueFn();
      }
    } catch (err) {
      console.error(err.message);
      showNotification(NotificationTypes.error, 'Error Saving Mapping', 'Server Error');
    }
  };

  const cancel = (cancelAndBacktrack?: boolean) => {
    if (cancelAndBacktrack) {
      props.previousFn();
    } else {
      props.cancelFn();
    }
  };

  const changeStep = (newStep: number) => {
    props.changeStepFn(newStep);
  };

  const onOk = async () => {
    dispatch(dataActions.resetReduxStates());
    history.push(`/import-history`);
  };

  const donotOverwriteNavianceValueIfBlankFn = () => {
    dispatch(dataActions.setDonotOverwriteNavianceValueIfBlank());
  };

  const donotOverwriteNavianceValueIfMetFn = () => {
    dispatch(dataActions.setDonotOverwriteNavianceValueIfMet());
  };

  const overwriteDataFn = () => {
    dispatch(dataActions.setOverwriteData());
  };

  const mainContent = (
    <>
      <div className="subSections" style={{ height: '100vh' }}>
        <div className="subSection">
          <h1 className="start-import-header">Review your import details and start the import.</h1>

          <div className="review-import-details">
            <div>
              <strong>Data File:</strong> {fileName}
            </div>
            <div>
              <strong>Data Type:</strong> {dataTypeName}
            </div>
            <div>
              <strong>Field Matching:</strong> {matchedFieldsCount} Matched, {unmatchedFieldsCount} Unmatched
            </div>
          </div>

          <div className="review-import-details-checkbox">
            <div>
              <Checkbox
                checked={donotOverwriteNavianceValueIfBlank}
                className="checkbox-review-import"
                onChange={donotOverwriteNavianceValueIfBlankFn}
              >
                Do not overwrite values in Naviance if blank in the file.
              </Checkbox>
            </div>
            <div>
              <Checkbox checked={overwriteData} className="checkbox-review-import" onChange={overwriteDataFn}>
                Overwrite existing data for students in file.
              </Checkbox>
            </div>
            {dataTypeFormat === 'Custom' && (
              <div>
                <Checkbox
                  checked={donotOverwriteNavianceValueIfMet}
                  className="checkbox-review-import"
                  onChange={donotOverwriteNavianceValueIfMetFn}
                >
                  Do not overwrite fields that have already been marked as “met”.
                </Checkbox>
              </div>
            )}
          </div>
        </div>

        <div style={{ marginBottom: '40px' }}>
          <Col span={8}>
            <Divider />
          </Col>
        </div>
        <div>
          <button className="test-import-button" onClick={() => startImport(true)}>
            Test Import
          </button>
          <button className="button start-import-button" onClick={() => startImport()}>
            Start Import
          </button>
        </div>
      </div>
    </>
  );

  return (
    <>
      <Wizard
        steps={props.steps}
        currentStep={props.currentStep}
        continueFn={() => save(true)}
        previousFn={() => cancel(true)}
        cancelFn={() => cancel()}
        changeStepFn={changeStep}
        hideHeaderButtons={true}
        pageLoading={loading}
      >
        {showErrorAlert ? (
          <Alert
            message={<Title level={5}>{'Error!'}</Title>}
            description={
              <span>
                There was an unexpected system error while processing this import. Please contact the customer support
                team if the problem persists. <br /> <br /> Click here to go back to{' '}
                <a href="/data-import-new">
                  {' '}
                  Data Import <sup>New</sup>
                </a>{' '}
                page.
              </span>
            }
            type="error"
            icon={<AlertErrorIcon customStyles={{ ...styles, width: '40px' }} />}
            showIcon
          />
        ) : (
          mainContent
        )}
      </Wizard>
      <Modal
        visible={showConfirmationModal}
        onOk={onOk}
        onCancel={onOk}
        cancelButtonProps={{ hidden: true }}
        wrapClassName="start_import_modal"
        okText="Done"
      >
        <Title level={5}>Your import has started!</Title>
        <ul>
          <li>
            Click <b>Done</b> to view your import status on the Data Import History page.
          </li>
          <li>The import may take a few minutes to complete.</li>
        </ul>
      </Modal>
    </>
  );
};

export default TSStartImport;
