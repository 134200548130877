import { Col, Divider, Row } from 'antd';
import Title from 'antd/lib/typography/Title';
import * as React from 'react';
export interface SubheaderProps {
  title: string;
  titleTestId?: string;
  titleClassName?: string;
  subtitle?: string;
  subtitleTestId?: string;
  subheaderRSContent?: React.ReactElement;
  showDivider?: boolean;
  superScript?: string;
}

export const Subheader = (props: SubheaderProps): React.ReactElement => {
  return (
    <div className="main-header-sec">
      <Row justify="space-between" align="middle">
        <Col className="titleCol" span="auto">
          <Title className={props.titleClassName || 'titleField'} level={1} data-test-id={props.titleTestId || 'title'}>
            {props.title}
            {props.superScript && <span>{props.superScript}</span>}
          </Title>
          {props.subtitle && (
            <Title className="titleField" level={2} data-test-id={props.subtitleTestId || 'subtitle'}>
              {props.subtitle}
            </Title>
          )}
        </Col>
        <Col span="auto">{props.subheaderRSContent}</Col>
      </Row>
      {props.showDivider && <Divider className="divider-data" />}
    </div>
  );
};

export default Subheader;
