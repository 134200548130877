import React from 'react';
import Icon from '@ant-design/icons';

export const ToolTip = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10 2C14.4183 2 18 5.58172 18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2ZM9.76246 13.5C9.40808 13.5 9.10908 13.6198 8.86545 13.8595C8.62181 14.0992 8.5 14.3946 8.5 14.7458C8.5 15.1416 8.62597 15.4496 8.87791 15.6697C9.12985 15.8899 9.42469 16 9.76246 16C10.0891 16 10.3771 15.8885 10.6262 15.6656C10.8754 15.4426 11 15.136 11 14.7458C11 14.3946 10.881 14.0992 10.6429 13.8595C10.4048 13.6198 10.1113 13.5 9.76246 13.5ZM7.912 4.48399C8.536 4.16133 9.264 4 10.096 4C10.8693 4 11.552 4.13486 12.144 4.40458C12.736 4.6743 13.1933 5.04107 13.516 5.50489C13.8387 5.96872 14 6.47286 14 7.01735C14 7.44588 13.908 7.82147 13.724 8.14413C13.54 8.46679 13.3213 8.74533 13.068 8.97976C13.0427 9.0032 13.0153 9.02825 12.986 9.05489L12.537 9.45146L11.704 10.1633C11.5227 10.3195 11.3773 10.4569 11.268 10.5754C11.1587 10.6939 11.0773 10.8023 11.024 10.9006C10.9707 10.9989 10.9293 11.0972 10.9 11.1955L10.8578 11.3492L10.7382 11.8391C10.6123 12.2797 10.3049 12.5 9.816 12.5C9.53867 12.5 9.30533 12.4143 9.116 12.2429C8.92667 12.0715 8.832 11.8169 8.832 11.4791C8.832 11.0556 8.90133 10.6888 9.04 10.3788C9.17867 10.0687 9.36267 9.79649 9.592 9.56206C9.66844 9.48391 9.75378 9.40087 9.848 9.31292L10.1573 9.03438C10.2693 8.93663 10.3902 8.83398 10.52 8.72642L10.6605 8.60967L11.0124 8.31034L11.26 8.08741C11.412 7.94373 11.54 7.78366 11.644 7.60721C11.748 7.43075 11.8 7.23918 11.8 7.03247C11.8 6.62915 11.6413 6.28885 11.324 6.01157C11.0067 5.73428 10.5973 5.59564 10.096 5.59564C9.50933 5.59564 9.07733 5.73554 8.8 6.01535C8.52267 6.29515 8.288 6.70729 8.096 7.25178C7.91467 7.82147 7.57067 8.10632 7.064 8.10632C6.76533 8.10632 6.51333 8.00675 6.308 7.80761C6.10267 7.60847 6 7.39294 6 7.16103C6 6.68209 6.16266 6.19684 6.488 5.70529C6.81333 5.21374 7.288 4.80664 7.912 4.48399Z"
      fill="#677274"
    />
    <path
      opacity="0.5"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0ZM10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1Z"
      fill="#677274"
    />
  </svg>
);

const ToolTipIcon = ({ inactive = false, editSubjectArea = false, ...props }) => {
  return <Icon component={ToolTip} {...props} />;
};

export default ToolTipIcon;
