import { ALL_STATES_CODE, ALL_STATES_MAP } from './constants';
import { DataTypesType } from '../interfaces/DataTypesType';
import { featureFlags } from './featureFlags';
import { FeatureFlag } from '../enums/FeatureFlag';

export const availabilityCheck = (dataType: DataTypesType): boolean => {
  const userState = window.DATA_IMPORT_NEW_UI_CONTEXT?.props?.state;
  if (featureFlags[FeatureFlag.SUPPORT_FULL_STATE_NAMES]) {
    const userStateTrimLC = userState.toLowerCase().trim();
    const state = ALL_STATES_MAP.find((i) => i.label.toLowerCase() === userStateTrimLC);
    const availability = dataType.availability.toLowerCase();
    return (
      availability === userStateTrimLC ||
      availability === ALL_STATES_CODE.toLowerCase() ||
      availability === state?.value.toLowerCase()
    );
  } else {
    return dataType.availability === userState || dataType.availability === ALL_STATES_CODE;
  }
};
