import React from 'react';
import DefaultIcon from '../DefaultIcon';

const Loader = () => (
  <DefaultIcon>
    <g>
      <path
        opacity="1"
        d="M12.5,0c-0.4,0-0.7,0.3-0.7,0.7v4.7c0,0.4,0.3,0.7,0.7,0.7c0.4,0,0.7-0.3,0.7-0.7V0.7C13.2,0.3,12.9,0,12.5,0z"
      />
      <path
        opacity="0.5"
        d="M12.5,18.8c-0.4,0-0.7,0.3-0.7,0.7v4.7c0,0.4,0.3,0.7,0.7,0.7c0.4,0,0.7-0.3,0.7-0.7v-4.7C13.2,19.2,12.9,18.8,12.5,18.8
				z"
      />
      <path
        opacity="0.9"
        d="M9.6,6L7.3,1.9C7,1.6,6.6,1.5,6.3,1.7C5.9,1.9,5.8,2.3,6,2.7l2.4,4.1C8.5,7,8.7,7.1,9,7.1c0.1,0,0.3,0,0.4-0.1
				C9.7,6.8,9.8,6.4,9.6,6z"
      />
      <path
        opacity="0.4"
        d="M19,22.3l-2.4-4.1c-0.2-0.4-0.7-0.5-1-0.3c-0.4,0.2-0.5,0.7-0.3,1l2.4,4.1c0.1,0.2,0.4,0.4,0.6,0.4c0.1,0,0.3,0,0.4-0.1
				C19.1,23.1,19.2,22.7,19,22.3z"
      />
      <path
        opacity="0.8"
        d="M6.8,8.3L2.7,6c-0.4-0.2-0.8-0.1-1,0.3C1.5,6.6,1.6,7,1.9,7.3L6,9.6c0.1,0.1,0.2,0.1,0.4,0.1c0.3,0,0.5-0.1,0.6-0.4
				C7.2,9,7.1,8.5,6.8,8.3z"
      />
      <path
        opacity="0.35"
        d="M23.1,17.7L19,15.4c-0.4-0.2-0.8-0.1-1,0.3c-0.2,0.4-0.1,0.8,0.3,1l4.1,2.4c0.1,0.1,0.2,0.1,0.4,0.1
				c0.3,0,0.5-0.1,0.6-0.4C23.5,18.4,23.4,18,23.1,17.7z"
      />
      <path
        opacity="0.7"
        d="M5.4,11.8H0.7c-0.4,0-0.7,0.3-0.7,0.7s0.3,0.7,0.7,0.7h4.7c0.4,0,0.7-0.3,0.7-0.7S5.8,11.8,5.4,11.8z"
      />
      <path
        opacity="0.3"
        d="M24.3,11.8h-4.7c-0.4,0-0.7,0.3-0.7,0.7s0.3,0.7,0.7,0.7h4.7c0.4,0,0.7-0.3,0.7-0.7S24.7,11.8,24.3,11.8z"
      />
      <path
        opacity="0.6"
        d="M7,15.7c-0.2-0.4-0.7-0.5-1-0.3l-4.1,2.4c-0.4,0.2-0.5,0.7-0.3,1c0.1,0.2,0.4,0.4,0.6,0.4c0.1,0,0.3,0,0.4-0.1l4.1-2.4
				C7.1,16.5,7.2,16,7,15.7z"
      />
      <path
        opacity="0.2"
        d="M23.3,6.3c-0.2-0.4-0.7-0.5-1-0.3l-4.1,2.4c-0.4,0.2-0.5,0.7-0.3,1c0.1,0.2,0.4,0.4,0.6,0.4c0.1,0,0.3,0,0.4-0.1l4.1-2.4
				C23.4,7,23.5,6.6,23.3,6.3z"
      />
      <path
        opacity="0.55"
        d="M9.3,18c-0.4-0.2-0.8-0.1-1,0.3L6,22.3c-0.2,0.4-0.1,0.8,0.3,1c0.1,0.1,0.2,0.1,0.4,0.1c0.3,0,0.5-0.1,0.6-0.4L9.6,19
				C9.8,18.6,9.7,18.2,9.3,18z"
      />
      <path
        opacity="0.1"
        d="M18.8,1.7c-0.4-0.2-0.8-0.1-1,0.3L15.4,6c-0.2,0.4-0.1,0.8,0.3,1c0.1,0.1,0.2,0.1,0.4,0.1c0.3,0,0.5-0.1,0.6-0.4L19,2.7
				C19.2,2.3,19.1,1.9,18.8,1.7z"
      />
    </g>
  </DefaultIcon>
);

export default Loader;
