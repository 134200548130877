import * as React from 'react';
import Subheader, { SubheaderProps } from './Subheader';
export interface MainTemplateProps extends SubheaderProps {
  children: React.ReactElement;
  classes?: string;
  showDivider?: boolean;
  superScript?: string;
}
export const MainTemplate = (props: MainTemplateProps): React.ReactElement => {
  return (
    <div className={props.classes ?? 'new-data-import mainMatchFieldsSection mainConfigSection'}>
      <Subheader
        title={props.title}
        titleTestId={props.titleTestId}
        titleClassName={props.titleClassName}
        subtitle={props.subtitle}
        subtitleTestId={props.subtitleTestId}
        subheaderRSContent={props.subheaderRSContent}
        showDivider={props.showDivider}
        superScript={props.superScript}
      />
      <div className="mainPerformTimeWrapper">{props.children}</div>
    </div>
  );
};

export default MainTemplate;
