import { apiClient } from './apiClient';
import { ApiClientType } from './constants';

let featureFlags: Record<string, boolean> = {};

export const getFeatureFlags = async (): Promise<void> => {
  const { data } = await apiClient({ url: `/features`, method: 'get' }, ApiClientType.SUCCEED_API);
  featureFlags = data;
};

// below method should only be used to mock flags in unit tests
export const setFeatureFlags = (flags: Record<string, boolean>): void => {
  featureFlags = flags;
};

export { featureFlags };
