import * as React from 'react';
import TSImport from '.';
import TSStartImport from '../TSStartImport';
import TSDefineCodes from '../TSDefineCodes';
import TSMatchFields from '../TSMatchFields';
import TSFileUpload from '../TSFileupload';

const TestScoreImport = (): React.ReactElement => {
  const TSImportSteps = [
    { title: 'Upload File', content: TSFileUpload },
    { title: 'Match Fields', content: TSMatchFields },
    { title: 'Define Codes', content: TSDefineCodes },
    { title: 'Test / Start Import', content: TSStartImport },
  ];

  return <TSImport steps={TSImportSteps} />;
};

export default TestScoreImport;
