import * as React from 'react';
import { Row, Col, Space, Divider } from 'antd';
import Layout, { Content, Footer } from 'antd/lib/layout/layout';
import Sider from 'antd/lib/layout/Sider';
import Title from 'antd/lib/typography/Title';
import WizardSideBar, { WizardStep } from './sideBar';
import { CancelSaveContinue } from '../CancelSaveContinue';
import Spinner from '../Spinner';

export interface WizardProps {
  cancelFn: () => void;
  previousFn: () => void;
  continueFn: () => void;
  changeStepFn: (newStep: number) => void;
  steps: WizardStep[];
  currentStep: number;
  children: React.ReactElement;
  nextButton?: React.ReactElement;
  hideHeaderButtons?: boolean;
  pageLoading?: boolean;
  disableSaveNext?: boolean;
}

const Wizard = (props: WizardProps): React.ReactElement => {
  const [hideContinue, setHideContinue] = React.useState(false);

  React.useEffect(() => {
    setHideContinue(props.currentStep >= props.steps.length - 1);
  }, [props.currentStep]);

  return (
    <>
      <Layout className="main-content" hasSider={true}>
        <Sider className="sidebar-content">
          <WizardSideBar steps={props.steps} onChange={props.changeStepFn} />
        </Sider>
        <Content>
          <div className="new-data-import mainMatchFieldsSection mainConfigSection">
            <div className="mainPerformTimeWrapper">
              <div className="wizard-content-header">
                <Title level={3}>{props.steps[props.currentStep].title}</Title>
                {props.nextButton
                  ? props.nextButton
                  : !props.hideHeaderButtons && (
                      <CancelSaveContinue
                        cancelFn={props.cancelFn}
                        continueFn={props.continueFn}
                        continueText="Next"
                        disableContinue={props.disableSaveNext}
                        // saveFn={props.saveFn}
                        hideContinue={hideContinue}
                        saveAndCloseBtn={true}
                      />
                    )}
              </div>
              <Divider></Divider>
              {props.pageLoading ? <Spinner /> : props.children}
            </div>
          </div>
        </Content>
      </Layout>
      <Footer className="wizard-footer" style={{ position: 'sticky', bottom: '0' }}>
        <Row className="wizard-footer-main">
          <Col span="auto">
            <Space>
              <button
                className="button"
                data-test-id="previous_btn"
                onClick={() => props.previousFn()}
                disabled={props.currentStep === 0}
                hidden={props.currentStep === 0}
              >
                Previous
              </button>
            </Space>
          </Col>
          {props.nextButton ? (
            props.nextButton
          ) : (
            <CancelSaveContinue
              cancelFn={props.cancelFn}
              continueFn={props.continueFn}
              continueText="Next"
              disableContinue={false}
              // saveFn={props.saveFn}
              hideContinue={hideContinue}
              saveAndCloseBtn={true}
              placement="topRight"
            />
          )}
        </Row>
      </Footer>
    </>
  );
};

export default Wizard;
