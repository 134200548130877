import React from 'react';
import CustomIcon from '../CustomIcon';

const styles = {
  width: '28px',
  verticalAlign: 'text-top',
};

const NewTabIcon = () => (
  <CustomIcon style={styles}>
    <path
      d="M6.99432 13.9935C5.25812 13.9935 3.52192 13.9639 1.78572 14.0034C0.627157 14.0298 -0.00328786 13.1294 1.28955e-05 12.1992C0.00661441 10.8732 1.28955e-05 9.54726 1.28955e-05 8.22129C1.28955e-05 6.08721 1.28955e-05 3.95313 1.28955e-05 1.81905C1.28955e-05 1.2979 0.145246 0.83282 0.518232 0.453501C0.802097 0.16324 1.14868 0.00491523 1.55467 0.00491523C2.89808 0.0016168 4.24149 0.0016168 5.5849 0.00491523C6.00079 0.00491523 6.30446 0.321564 6.29786 0.720674C6.28796 1.11319 5.98429 1.40015 5.575 1.40345C4.37352 1.40345 3.17204 1.40345 1.97057 1.40345C1.54477 1.40345 1.40284 1.54858 1.40284 1.98067C1.40284 5.12077 1.40284 8.26088 1.40284 11.4043C1.40284 11.6319 1.40284 11.8595 1.40284 12.0871C1.40944 12.4301 1.57778 12.595 1.92105 12.595C5.30103 12.595 8.68431 12.595 12.0643 12.595C12.4307 12.595 12.5957 12.4367 12.5957 12.0673C12.5957 10.8501 12.5957 9.63302 12.5957 8.4159C12.5957 7.95412 12.9753 7.63747 13.4242 7.71664C13.7444 7.77271 13.9886 8.04648 13.9919 8.39281C13.9985 9.70559 14.0084 11.0217 13.9853 12.3344C13.9721 13.1261 13.4737 13.766 12.7938 13.9441C12.6485 13.9836 12.4901 13.9968 12.3383 13.9968C10.5558 14.0001 8.77343 13.9968 6.99432 13.9968V13.9935Z"
      fill="#666666"
    />
    <path
      d="M12.5363 2.51157C12.4967 2.56764 12.4604 2.62701 12.4108 2.67319C10.7902 4.29602 9.16619 5.91554 7.54882 7.53837C7.30457 7.78245 7.0306 7.888 6.70053 7.78245C6.18231 7.61753 6.00407 6.97104 6.35725 6.55874C6.40676 6.50266 6.45957 6.44989 6.51238 6.40041C8.12645 4.78748 9.74053 3.17455 11.3546 1.56162C11.3975 1.51874 11.4371 1.47586 11.5064 1.4033C11.3117 1.4033 11.1533 1.4033 10.9981 1.4033C10.6119 1.4033 10.2224 1.4033 9.83625 1.4033C9.41375 1.4033 9.10678 1.11304 9.10018 0.713926C9.09358 0.311518 9.40715 0.00476439 9.83625 0.00146597C10.3941 -0.00183246 10.9519 0.00146597 11.5097 0.00146597C12.0907 0.00146597 12.6716 0.00146597 13.2525 0.00146597C13.6981 0.00146597 13.9985 0.295026 13.9985 0.737015C14.0018 1.88157 13.9985 3.02282 13.9985 4.16738C13.9985 4.55659 13.7476 4.84356 13.3846 4.88973C13.0347 4.93261 12.7112 4.72151 12.6221 4.37518C12.6023 4.29932 12.599 4.21686 12.599 4.13769C12.599 3.65942 12.599 3.18115 12.599 2.70288C12.599 2.6468 12.599 2.59403 12.599 2.53796L12.5396 2.50497L12.5363 2.51157Z"
      fill="#666666"
    />
  </CustomIcon>
);

export default NewTabIcon;
